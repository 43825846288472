<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_reservationConfirm" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="mt-1 d-flex gap-3">
            <!-- 取引先 -->
            <c-client-input
              v-model="commonForm.supplierSelected"
              required
              width="350px"
              class="first-search-item"
              ref="clientInfoRef"
            />
            <!-- v-row 1 取引先 終わり -->
            <!-- 空白 -->
            <span class="item-spacer"></span>

            <!-- v-row 1 出荷先 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-destination"
                v-model="shippingSelected"
                :items="shippingList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_destination')"
                class="txt-single search-autocomplete"
                :hint="setStsArrival()"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- v-row 1  終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- * -->

            <!-- v-row 1 出荷日 カレンダー 始まり -->
            <c-date-range-picker
              v-model="commonForm.shippingScheduleDate"
              :label="$t('label.lbl_scheduleDate')"
              required
            />
            <!-- v-row 1 出荷予定日 カレンダー 終わり -->

            <!-- v-row 1 ステータス 始まり -->
            <c-code-input
              v-model="commonForm.statusSelected"
              :codeType="appConfig.CODETYPE.STATUS_TYPE"
              width="250px"
            />
            <!-- v-row 1 ステータス 終わり -->
          </v-row>

          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <v-btn class="get-btn" @click="search">{{ $t("btn.btn_search") }}</v-btn>
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!-- ピッキングリスト発行ボタン -->
                <v-btn
                  v-if="pickingListExportRole == 'true'"
                  color="primary"
                  class="pick-btn"
                  @click="getPickingList()"
                >
                  {{ $t("btn.btn_pickingListIssue") }}
                </v-btn>
              </div>
            </div>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                  mandatory
                >
                  <v-btn class="pasing-btn" value="100" @click="u_10_on(100)" small>
                    {{ 100 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                    {{ 250 }}
                  </v-btn>
                  <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                    {{ 500 }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
            <!-- v-row 2 検索ボタン 終わり -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="resConfirmList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :ripple="false"
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="600px"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
              @click="checkList(item, index)"
              :check.sync="item.check"
            />
          </template>
          <!--出荷No.-->
          <template v-slot:[`item.outListNo`]="{ item }">
            <div class="listNo-style">{{ item.outListNo }}</div>
          </template>

          <!-- 輸送会社 -->
          <template v-slot:[`item.shipCampany`]="{ item }">
            <span v-if="item.shipCampany == ''">{{ item.carrierCompName }}</span>
            <span v-else>{{ item.shipCampany }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn small @click="openPicking(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- ピッキングリストダイアログ -->
      <v-dialog v-model="dialogPickingList" :max-width="1600" persistent no-click-animation>
        <PickingListParts
          :isOpenDetailDialog.sync="dialogPickingList"
          v-on:setSinglePicking="saveSinglePicking"
          :pickingInfo="pickingInfo"
          @close="search"
        />
      </v-dialog>

      <v-dialog v-model="infoDialog.isOpen" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :redMessage="infoDialog.redMessage"
          :firstPageFlag="infoDialog.firstPageFlag"
          :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        />
      </v-dialog>

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import PickingListParts from "../../views/common/PickingListParts";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_STK_IVEN_ALLOC_DEL,
  components: {
    Loading,
    sideMenu,
    NavBar,
    PickingListParts,
    SimpleDialog,
    ConfirmDialog,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    appConfig,
    commonForm: {
      // 取引先
      supplierSelected: "",
      // 出荷予定日(From)
      shippingScheduleDateFrom: "",
      // 出荷予定日(To)
      shippingScheduleDateTo: "",
      // ステータス
      statusSelected: "",
      shippingScheduleDate: {
        from: "",
        to: "",
      },
    },
    loadingCounter: 0,
    // ロケ仮置きデータ
    location: "",
    expireDateCal: "",
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // ソート
    sortItem: "",
    sortOptions: {},
    // 取引先SID
    clientSid: "",
    // 引当基準
    reserveBasisSelected: "",
    // 輸送会社
    routeAndCharteredSelected: "",
    // 出荷先
    shippingSelected: "",
    // メニュー
    openMenu: null,
    // ピッキングリストダイアログ
    dialogPickingList: false,
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },
    alertMessageInUnit: "",
    // 一覧
    resConfirmList: [],
    // 子へ渡す情報
    pickingInfo: {},
    // 出荷先
    shippingList: [],
    // 日付バリデーションメッセージ
    alertMessage: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // ピッキングリスト出力権限
    pickingListExportRole: "true",
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: i18n.tc("label.lbl_choice"),
        value: "check",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 出荷No.
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "outListNo",
        width: "22%",
        sortable: true,
        align: "left",
        class: "listNo-style",
      },
      // 出荷日
      {
        text: i18n.tc("label.lbl_scheduleDate"),
        value: "shipInsDate",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_destination"),
        value: "destination",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 輸送会社
      {
        text: i18n.tc("label.lbl_shippingCompanyName"),
        value: "shipCampany",
        width: "12%",
        align: "left",
        sortable: true,
      },
      // ピッキングNo
      {
        text: i18n.tc("label.lbl_pickingNo"),
        value: "pickingNo",
        width: "15%",
        sortable: true,
        align: "left",
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "action",
        width: "5%",
        align: "center",
        sortable: false,
      },
    ],
    valid: true,
    length: 0,
    getSuppliersSelected: "",
    productHintArrival: "",
    // 検索時の保持
    shippingSelectedSearchCondition: "",
    shippingListSearchCondition: [],
    searchShippingScheduleDateFrom: "",
    searchShippingScheduleDateFromCal: "",
    searchShippingScheduleDateTo: "",
    searchShippingScheduleDateToCal: "",
    searchStatusSelected: "",
  }),

  created() {
    // 取引先SID
    this.suppliersSelected = this.$route.params.searchSuppliersSelected;
    // 出荷先SID
    this.shippingSelected = this.$route.params.searchShippingSelected;
    // 出荷日From
    this.commonForm.shippingScheduleDate.from = this.$route.params.shippingScheduleDateFrom;
    // 出荷日To
    this.commonForm.shippingScheduleDate.to = this.$route.params.shippingScheduleDateTo;
    // ステータス
    this.statusSelected = this.$route.params.statusSelected;
    if (
      this.$route.params.dateToDeliveryCal != null ||
      this.$route.params.dateToDeliveryCal != ""
    ) {
      this.dateToDeliveryCal = this.$route.params.dateToDeliveryCal;
    }
    if (this.suppliersSelected) {
      this.productHintArrival = this.$route.params.shippingNm;
      this.shippingSelectedSearchCondition = this.shippingSelected;
      let shippingList = this.$route.params.shippingList;
      this.shippingListSearchCondition = this.shippingList;
      this.shippingList = shippingList ? shippingList : [];
      // 一覧取得
      this.getApiSearch(true);
    }
  },

  methods: {
    /**
     * ページングネーションの幅調整
     */
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on(val) {
      this.u_10 = true;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiSearch(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiSearch(false);
    },

    /**
     * ピッキングリスト、閉じる
     */
    saveSinglePicking() {
      this.dialogPickingList = false;
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.resConfirmList.length; i++) {
          this.resConfirmList[i].check = true;
          if (this.listCheckboxBtn.indexOf(this.resConfirmList[i]) == -1) {
            this.listCheckboxBtn.push(this.resConfirmList[i]);
            this.chengeListCheckbox.push(this.resConfirmList[i]);
          }
        }
        this.length = this.resConfirmList.length;
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.resConfirmList.length; j++) {
          this.resConfirmList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
          for (var b = 0; b < this.chengeListCheckbox.length; b++) {
            if (this.chengeListCheckbox[b].pickingNo != this.resConfirmList[j].pickingNo) {
              this.listCheckboxBtn.push(this.chengeListCheckbox[b]);
            }
          }
          this.chengeListCheckbox = this.listCheckboxBtn;
        }
        this.isAllSelected = false;
        this.length = 0;
      }
    },

    /**
     * ピッキングリスト子画面、値受け渡し
     */
    openPicking(item) {
      if (
        new Date(this.commonForm.shippingScheduleDate.from).getTime() >
        new Date(this.commonForm.shippingScheduleDate.to)
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        if (this.$refs.form.validate()) {
          // 選択した行のアイテム
          this.editedIndex = this.resConfirmList.indexOf(item);

          // ピッキングリスト名
          this.pickingInfo.pickingNm = this.resConfirmList[this.editedIndex].pickingNm;
          // ピッキングSID（ピッキングNo）
          this.pickingInfo.pickingNo = this.resConfirmList[this.editedIndex].pickingNo;
          // 取引先SID
          this.pickingInfo.suppliersSelected = this.clientSid;
          // 出荷予定日
          this.pickingInfo.shipInsDate = this.resConfirmList[this.editedIndex].shipInsDate;
          // 出荷先SID
          this.pickingInfo.toSid = this.resConfirmList[this.editedIndex].toSid;

          this.dialogPickingList = true;
        }
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.commonForm.statusSelected = "";
      this.commonForm.shippingScheduleDate.from = dateTimeHelper.convertJST().substring(0, 10);
      this.commonForm.shippingScheduleDate.to = dateTimeHelper.convertJST().substring(0, 10);
      this.resConfirmList = [];
      // ピッキングリスト出力権限を取得
      this.pickingListExportRole = sessionStorage.getItem("P-STK-006_PIKKING");
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        if (this.chengeListCheckbox.indexOf(item) == -1) {
          // 編集用リスト
          this.chengeListCheckbox.push(item);
        }
        if (this.listCheckboxBtn.indexOf(item) == -1) {
          // 画面遷移用リスト
          this.listCheckboxBtn.push(item);
        }
        this.length++;
        //全チェックされているか確認
        if (this.resConfirmList.length == this.length) {
          this.isAllSelected = true;
        }
      } else {
        // チェックを外した場合
        // 外したレコードのピッキングSIDを取得
        let inputListPickingGrSid = this.resConfirmList[index].pickingNo;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].pickingNo != inputListPickingGrSid) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
        this.length--;
        this.isAllSelected = false;
      }
    },

    /**
     * 検索前のバリデーションチェック
     */
    search() {
      if (this.$refs.form.validate()) {
        // バリデーションエラーメッセージを非表示
        this.alertMessage = "";
        this.getApiSearch(true);
      }
    },

    /**
     * 出庫指示サマリー取得（API実行）
     */
    getApiSearch(checkBoxFlag) {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SID
      config.params.clientSid = this.commonForm.supplierSelected;
      this.clientSid = this.commonForm.supplierSelected;
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // From日
      config.params.fromOutScheduleDate = dateTimeHelper.convertUTC(
        this.commonForm.shippingScheduleDate.from
      );
      // To日
      var arr = this.commonForm.shippingScheduleDate.to + " 23:59:59.999";
      config.params.toOutScheduleDate = dateTimeHelper.convertUTC(arr);

      // ページャー処理
      if (this.page >= 2 && this.resConfirmList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      // 出荷先選択の場合
      if (this.shippingSelected) {
        // ToSID（出荷先SID）
        config.params.toSid = this.shippingSelected;
      }
      // ステータス選択の場合
      if (this.commonForm.statusSelected) {
        // ０１未完了
        if (this.commonForm.statusSelected == "01") {
          // ステータス
          config.params.status = "0";
        } else if (this.commonForm.statusSelected == "02") {
          config.params.status = "1";
        }
      }

      // 検索条件保持
      this.shippingSelectedSearchCondition = this.commonForm.shippingSelected;
      this.shippingListSearchCondition =
        this.shippingSelected == "" || this.shippingSelected == null
          ? []
          : new Array(...this.shippingList);
      this.searchShippingScheduleDateFrom = this.commonForm.shippingScheduleDate.from;
      this.searchShippingScheduleDateTo = this.commonForm.shippingScheduleDate.to;
      this.searchStatusSelected = this.commonForm.statusSelected;
      // console.debug("getApiSearch(checkBoxFlag) Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_WAREOUTORDERSUMMARYPARTS, config)
          .then((response) => {
            // console.debug("getApiSearch(checkBoxFlag) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              let statusIn = "";
              const list = [];

              // 出庫指示サマリー
              jsonData.resIdv.wareOutOrderSummary.forEach((row) => {
                if (row.status === "0") {
                  // ステータス
                  statusIn = i18n.tc("label.lbl_unshipped");
                } else if (row.status === "1") {
                  statusIn = i18n.tc("label.lbl_shipped");
                }

                list.push({
                  // ピッキングSID(画面上、、ピッキングNo)
                  pickingNo: row.pickingGrSid,
                  // 出荷日
                  shipInsDate: dateTimeHelper.convertUTC2JST(row.outScheduleDate).substr(0, 10),
                  // 出荷先Sid
                  toSid: row.toSid,
                  // 拠点コード
                  baseCd: row.baseCd,
                  // 出荷先名
                  destination: row.toName,
                  // 運送会社SID
                  deliveryTraderSid: row.deliveryTraderSid,
                  // 運送会社名
                  shipCampany: row.deliveryTraderName,
                  // ステータス
                  status: statusIn,
                  // チェックボックス
                  check: false,
                  // ピッキング判断区分
                  picking: row.pickingDiv,
                  // ピッキング名
                  pickingNm: row.pickingDivName,
                  carrierCompCd: row.carrierCompCd, // 輸送会社区分 ★
                  carrierCompName: row.carrierCompName, // 輸送会社種別名 ★
                  // 取引先SID
                  clientSid: this.suppliersSelected,
                  //出荷No.
                  outListNo: row.outListNo,
                });
              });

              // 一覧結果リストに格納
              this.resConfirmList = list;
              this.length = 0;
              if (!checkBoxFlag) {
                // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                for (var k = 0; k < this.resConfirmList.length; k++) {
                  for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                    if (this.resConfirmList[k].pickingNo == this.listCheckboxBtn[j].pickingNo) {
                      this.resConfirmList[k].check = true;
                      this.length++;
                    }
                  }
                }
                if (this.length == this.resConfirmList.length) {
                  this.isAllSelected = true;
                } else {
                  this.isAllSelected = false;
                }
              } else {
                // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                this.listCheckboxBtn.length = 0;
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * ピッキングリスト発行API
     */
    getPickingList() {
      if (this.listCheckboxBtn.length > 0) {
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const body = this.$httpClient.createRequestBodyConfig();

          // 共通IF項目 画面ID
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_006;

          // チェック済ピッキンググループSIDリスト取得
          const noList = [];
          var pickingGrSid = "";

          for (var j = 0; j < this.listCheckboxBtn.length; j++) {
            if (this.listCheckboxBtn[j].check) {
              pickingGrSid = this.listCheckboxBtn[j].pickingNo;
              noList.push(pickingGrSid);
            }
          }

          // ピッキンググループSIDリストに設定
          body.reqIdv.pickingGrSids = noList;
          // console.debug("getPickingList() Body", body);
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_PICKING_LIST_PARTS, body, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getPickingList() Response", response);

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // 返却されたurlをデータに格納
                  this.returnURL = jsonData.resIdv.filePath;
                  this.link();
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  resolve(response);
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject(resolve);
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * URL発行
     */
    link() {
      location.href = this.returnURL;
    },

    /**
     * 出荷先コードリストボックスセット処理
     */
    setStsArrival() {
      for (var i = 0; i < this.shippingList.length; i++) {
        if (this.shippingList[i].value == this.shippingSelected) {
          return this.shippingList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 出荷先を選択時
      let val = this.shippingList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shippingList = this.shippingList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiSearch(false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_STK_002_SORT[sortBy].replaceAll("{0}", ascdesc);
          this.getApiSearch(false);
        }
      },
      deep: true,
    },
    /**
     * 出荷先
     */
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.commonForm.supplierSelected == null || this.commonForm.supplierSelected == "") {
          this.shippingList = [];
          this.shippingSelected = "";

          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          this.alertMessageInUnit = "";
          // 選択した会社コードを取得
          let officeCd = this.$refs.clientInfoRef.internalItems.find(
            (element) => element.value === this.commonForm.supplierSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shippingList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shippingList.length = 0;
          this.shippingList = [];
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//引当ボタン 左端個別合わせ
.reservation-btn {
  margin-right: 3%;
  width: 7rem;
}
.paging-position {
  margin-left: 4.5%;
}

.paging-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1.7rem;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}

// トータル検索数
.search-result {
  margin: 0 0 0 5%;
}

.required::after {
  content: " *";
  color: red;
  vertical-align: middle;
}
.pick-btn {
  margin: 0 0 0 12px;
  width: 10rem;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.text-overflow {
  width: 400px;
}

.dialog {
  background-color: white;
}

.btn-search {
  margin-left: 5%;
}
.link {
  text-decoration: none;
}
</style>
